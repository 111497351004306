<template>
  <div>
      <header>
				<!--CITY TOP WRAP START--> 
				<div class="city_top_wrap">
					<div class="container-fluid">
						<div class="city_top_logo">
							<figure>
								<h1><a href="#"><img :src="Belediyebilgiler.kapak" alt="kodeforest"></a></h1>
							</figure>
						</div>
						<div class="city_top_news">
							<span>Duyurular</span>
							<div class="city-news-slider">
								<div>
									<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry <i class="fa fa-star"></i></p>
								</div>
								<div>
									<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry <i class="fa fa-star"></i></p>
								</div>
								<div>
									<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry <i class="fa fa-star"></i></p>
								</div>
							</div>
						</div>
						<div class="city_top_social">
							<ul>
								<li><a target="_blank" :href="Belediyebilgiler.face"><i class="fa fa-facebook"></i></a></li>
								<li><a target="_blank" :href="Belediyebilgiler.twit"><i class="fa fa-twitter"></i></a></li>
								<li><a target="_blank" :href="Belediyebilgiler.twit"><i class="fa fa-linkedin"></i></a></li>
								<li><a target="_blank" :href="Belediyebilgiler.twit"><i class="fa fa-youtube"></i></a></li>
								<li><a target="_blank" :href="Belediyebilgiler.twit"><i class="fa fa-google"></i></a></li>
							</ul>
						</div>
						
					</div>
				</div>
				<!--CITY TOP WRAP END-->
				
				<!--CITY TOP NAVIGATION START-->
				<div class="city_top_navigation">
					<div class="container-fluid">
						<div class="row">
							<div class="col-md-12">
								<div class="navigation">
									<ul>
										<li>
                      <router-link to="/">
                        Ana Sayfa
                      </router-link>
                    </li>
										<li>
                      <a>BAŞKAN</a>
											<ul class="child">
                        <li>
                          <router-link to="/ozgecmis"> Başkan Özgeçmiş</router-link>
                        </li>
                        <li>
                          <router-link to="/mesaj"> Başkan Mesaj</router-link>
                        </li>
                        <li>
                          <router-link to="/baskangaleri"> Başkan Galeri</router-link>
                        </li>
                        <li>
                          <router-link to="/baskanasor"> Banşkan'a Sor</router-link>
                        </li>
                        <li>
                          <router-link to="/eskibaskanlar"> Belediye Başkanlarımız</router-link>
                        </li>
											</ul>
										</li>
										<li><a href="#">Kurumsal</a>
											<ul class="child">
                          <li><router-link class="dropdown-item" to="/birimler"> Birimler</router-link></li>
                          <li><router-link class="dropdown-item" to="/meclisuyeleri">Meclis Üyeleri</router-link></li>
                          <li><router-link class="dropdown-item" to="/encumenlarimiz">Encümenlerimiz</router-link></li>
                          <li><router-link class="dropdown-item" to="/kararlar">Meclis Kararları</router-link></li>
                          <li><router-link class="dropdown-item" to="/raporlar">Faaliyet Raporları</router-link></li>
                          <li><router-link class="dropdown-item" to="/raporlar">Meclis Gündemleri</router-link></li>
                          <li><router-link class="dropdown-item" to="/tarihce">Etik Komisyonu</router-link></li>
                          <li><router-link class="dropdown-item" to="/kvkk">KVKK Aydınlatma Metni</router-link></li>
											</ul>
										</li>
										<li><a href="#">Güncel</a>
											<ul class="child">
                        <li><router-link class="dropdown-item" to="/haberler"> Haberler </router-link></li>
                        <li><router-link class="dropdown-item" to="/projeler"> Projeler </router-link></li>
                        <li><router-link class="dropdown-item" to="/duyuruilanlar">Duyurular</router-link></li>
											</ul>
										</li>
										<li><a href="#">Hizmetlerimiz</a>
											<ul class="child">
                        <li><a class="dropdown-item" :href="Belediyebilgiler.ebelediye">E-Belediye</a></li>
                        <li><a class="dropdown-item" :href="Belediyebilgiler.nobetciecz">Nöbetçi Eczaneler</a></li>
                        <li><router-link class="dropdown-item" to="/belgeler">Belge Örnekleri</router-link></li>
                        <li><a class="dropdown-item" href="http://web.karaman.bel.tr:571/Mebis/VefatListesi.aspx">Vefat Edenler</a></li>
                        <li><router-link class="dropdown-item" to="/evlenenler">Nikah Duyuruları</router-link></li>
											</ul>
										</li>
										<!-- <li><a href="#">feature</a>
											<ul class="child">
												<li><a href="#">blog</a>
													<ul class="child">
														<li><a href="blog.html">blog</a></li>
														<li><a href="blog-list.html">blog list</a></li>
														<li><a href="blog-detail.html">blog detail</a></li>
													</ul>
												</li>
												<li><a href="#">forum</a>
													<ul class="child">
														<li><a href="forum.html">forum</a></li>
														<li><a href="forum-01.html">forum 01</a></li>
														<li><a href="forum-detail.html">forum detail</a></li>
													</ul>
												</li>
												<li><a href="#">project</a>
													<ul class="child">
														<li><a href="project.html">project</a></li>
														<li><a href="project-01.html">project 01</a></li>
														<li><a href="project-detail.html">project detail</a></li>
													</ul>
												</li>
												<li><a href="login.html">login</a></li>
												<li><a href="error.html">404 page</a></li>
												<li><a href="coming-soon.html">coming soon</a></li>
											</ul>
										</li>
										<li><a href="#">New & Event</a>
											<ul class="child">
												<li><a href="#">event</a>
													<ul class="child">
														<li><a href="event.html">event</a></li>
														<li><a href="event-01.html">event 01</a></li>
														<li><a href="event-02.html">event 02</a></li>
														<li><a href="event-detail.html">event detail</a></li>
														<li><a href="event-listing.html">event listing</a></li>
													</ul>	
												</li>
												<li><a href="#">news</a>
													<ul class="child">
														<li><a href="news.html">news page</a></li>
														<li><a href="news-post.html">news post</a></li>
													</ul>
												</li>
											</ul>
										</li> -->
										<li><router-link class="nav-link active" to="/iletisim"> İletişim</router-link></li>
									</ul>									
								</div>
								<!--DL Menu Start-->
								<div id="kode-responsive-navigation" class="dl-menuwrapper">
									<button class="dl-trigger">Open Menu</button>
									<ul class="dl-menu">
										<li>
                      <router-link to="/">
                        Ana Sayfa
                      </router-link>
                    </li>
                    
										<li class="menu-item kode-parent-menu"><a href="#">Başkan</a>
											<ul class="dl-submenu">
												<li>
                          <router-link to="/ozgecmis"> Başkan Özgeçmiş</router-link>
                        </li>
                        <li>
                          <router-link to="/mesaj"> Başkan Mesaj</router-link>
                        </li>
                        <li>
                          <router-link to="/baskangaleri"> Başkan Galeri</router-link>
                        </li>
                        <li>
                          <router-link to="/baskanasor"> Banşkan'a Sor</router-link>
                        </li>
                        <li>
                          <router-link to="/eskibaskanlar"> Belediye Başkanlarımız</router-link>
                        </li>
											</ul>
										</li>
										<li class="menu-item kode-parent-menu"><a href="#">Kurumsal</a>
											<ul class="dl-submenu">
                          <li><router-link class="dropdown-item" to="/birimler"> Birimler</router-link></li>
                          <li><router-link class="dropdown-item" to="/meclisuyeleri">Meclis Üyeleri</router-link></li>
                          <li><router-link class="dropdown-item" to="/encumenlarimiz">Encümenlerimiz</router-link></li>
                          <li><router-link class="dropdown-item" to="/kararlar">Meclis Kararları</router-link></li>
                          <li><router-link class="dropdown-item" to="/raporlar">Faaliyet Raporları</router-link></li>
                          <li><router-link class="dropdown-item" to="/raporlar">Meclis Gündemleri</router-link></li>
                          <li><router-link class="dropdown-item" to="/tarihce">Etik Komisyonu</router-link></li>
                          <li><router-link class="dropdown-item" to="/kvkk">KVKK Aydınlatma Metni</router-link></li>
										</ul>
										</li>
										<li class="menu-item kode-parent-menu"><a href="#">Güncel</a>
											<ul class="dl-submenu">
                        <li><router-link class="dropdown-item" to="/haberler"> Haberler </router-link></li>
                        <li><router-link class="dropdown-item" to="/projeler"> Projeler </router-link></li>
                        <li><router-link class="dropdown-item" to="/duyuruilanlar">Duyurular</router-link></li>
											</ul>
										</li>
										<li class="menu-item kode-parent-menu"><a href="#">Hizmetlerimiz</a>
											<ul class="dl-submenu">
                        <li><a class="dropdown-item" :href="Belediyebilgiler.ebelediye">E-Belediye</a></li>
                        <li><a class="dropdown-item" :href="Belediyebilgiler.nobetciecz">Nöbetçi Eczaneler</a></li>
                        <li><router-link class="dropdown-item" to="/belgeler">Belge Örnekleri</router-link></li>
                        <li><a class="dropdown-item" href="http://web.karaman.bel.tr:571/Mebis/VefatListesi.aspx">Vefat Edenler</a></li>
                        <li><router-link class="dropdown-item" to="/evlenenler">Nikah Duyuruları</router-link></li>
											</ul>
										</li>
										<!-- <li class="menu-item kode-parent-menu"><a href="#">feature</a>
											<ul class="dl-submenu">
												<li><a href="#">blog</a>
													<ul class="dl-submenu">
														<li><a href="blog.html">blog</a></li>
														<li><a href="blog-list.html">blog list</a></li>
														<li><a href="blog-detail.html">blog detail</a></li>
													</ul>
												</li>
												<li><a href="#">forum</a>
													<ul class="dl-submenu">
														<li><a href="forum.html">forum</a></li>
														<li><a href="forum-01.html">forum 01</a></li>
														<li><a href="forum-detail.html">forum detail</a></li>
													</ul>
												</li>
												<li><a href="#">project</a>
													<ul class="dl-submenu">
														<li><a href="project.html">project</a></li>
														<li><a href="project-01.html">project 01</a></li>
														<li><a href="project-detail.html">project detail</a></li>
													</ul>
												</li>
												<li><a href="login.html">login</a></li>
												<li><a href="error.html">404 page</a></li>
												<li><a href="coming-soon.html">coming soon</a></li>
											</ul>
										</li>
										<li class="menu-item kode-parent-menu"><a href="#">New & Event</a>
											<ul class="dl-submenu">
												<li><a href="#">event</a>
													<ul class="dl-submenu">
														<li><a href="event.html">event</a></li>
														<li><a href="event-01.html">event 01</a></li>
														<li><a href="event-02.html">event 02</a></li>
														<li><a href="event-detail.html">event detail</a></li>
														<li><a href="event-listing.html">event listing</a></li>
													</ul>	
												</li>
												<li><a href="#">news</a>
													<ul class="dl-submenu">
														<li><a href="news.html">news page</a></li>
														<li><a href="news-post.html">news post</a></li>
													</ul>
												</li>
											</ul>
										</li> -->
										<li><router-link class="nav-link active" to="/iletisim"> İletişim</router-link></li>
									</ul>
								</div>
								<!--DL Menu END-->
							</div>
						</div>
					</div>
				</div>	
				<!--CITY TOP NAVIGATION END-->
			</header>
  </div>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      Belediyebilgiler: {},
      domAd: "",
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    this.domAd = domainAd;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
  },
};
</script>

<style>
.dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: #217c3d;
}
</style>