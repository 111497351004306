<template>
  <div>
  <!--CITY MAIN BANNER START-->
			<div class="city_main_banner">
				<div class="main-banner-slider">
				<div class="container-fluid">
        <div class="row">
          <div class="col">
            <div class="swiper-container mySwiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide"
                v-for="(slide, index ) in Slides.Dosyalar" 
                :key="index"
                :class="[Slides,{ 'active' : index === 0 }]"
                >
                <img :src="slide.dosyaurl" target="_blank" class="d-block w-100 " alt="...">
                </div>
              </div>
              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>
            </div>
          </div>
        </div>
      </div>
				</div>
			</div>
	<!--CITY MAIN BANNER END-->
		
  <!--CITY BANNER SERVICES START-->
			<div class="city_banner_services">
				<div class="container-fluid">
					<div class="city_service_list">
						<ul>
							<li>
								<div class="city_service_text">
									<span><i class="fa icon-news"></i></span>
									<h5>
                    <router-link to="encümenlerimiz">
                      Encümenlerimiz
                    </router-link>
                  </h5>
								</div>
							</li>
							<li>
								<div class="city_service_text">
									<span><i class="fa icon-law"></i></span>
									<h5>
                    <router-link to="/kararlar">
                      Meclis Kararları
                    </router-link>
                  </h5>
								</div>
							</li>
							<li>
								<div class="city_service_text">
									<span><i class="fa icon-cursor"></i></span>
									<h5><a :href="Belediyebilgiler.ebelediye">E-Belediye</a></h5>
								</div>
							</li>
							<li>
								<div class="city_service_text">
									<span><i class="fa icon-trash"></i></span>
									<h5>
                    <router-link to="/raporlar">
                      Faaliyet Raporları
                    </router-link>
                  </h5>
								</div>
							</li>							
						</ul>
					</div>
				</div>
			</div>
	<!--CITY BANNER SERVICES END-->

  <!--CITY ABOUT WRAP START-->
			<div class="city_about_wrap">
				<div class="container">
					<div class="row">
						<div class="col-md-6">
							<div class="city_about_fig">
								<figure class="box">
									<div class="box-layer layer-1"></div>
									<div class="box-layer layer-2"></div>
									<div class="box-layer layer-3"></div>
									<img :src="Belediyebilgiler.anaresim" alt="">
								</figure>
							</div>
						</div>
						<div class="col-md-6">
							<div class="city_about_list">
								<!--SECTION HEADING START-->
								<div class="section_heading border">
									<span>{{Belediyebilgiler.belediyeadi}} Dünden Bugüne Tarihimiz</span>
									<h2>Tarihçe</h2>
								</div>
								<!--SECTION HEADING END-->
								<div class="city_about_text">
									<p> {{ Ilce.tarihce.slice(0,1000) }} </p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	<!--CITY ABOUT WRAP END-->

  <!--CITY BLOG WRAP START-->
			<div class="city_blog_wrap">
				<div class="container">
					<!--SECTION HEADING START-->
					<div class="heding_full">
						<div class="section_heading">
							<h2>HABERLER</h2>
							<div class="city_blog_social">
								<a class="theam_btn border-color color" tabindex="0">
                  <router-link to="/haberler">
                    Tüm Haberler
                  </router-link>
                </a>
							</div>
						</div>
					</div>
					<!--SECTION HEADING END-->
					<div class="row">
						<div class="col-md-4 col-sm-4"
            v-for="(haber,index) in Haberler.slice(0,3)"
            :key="index"
            >
							<div class="city_blog_fig">
								<figure class="box">
									<div class="box-layer layer-1"></div>
									<div class="box-layer layer-2"></div>
									<div class="box-layer layer-3"></div>
									<img :src="haber.kapak" alt="">
								</figure>
								<div class="city_blog_text">
									<span>{{haber.haberTarihi}}</span>
									<h4>{{haber.haberAdi}}</h4>
									<div class="city_blog_social">
										<a class="theam_btn border-color color" @click="detayagit(haber.haberId)" tabindex="0">Habere Git</a>
									</div>
								</div>
							</div>
							<div class="city_blog_fig position">
								<figure class="box">
									<div class="box-layer layer-1"></div>
									<div class="box-layer layer-2"></div>
									<div class="box-layer layer-3"></div>
									<img :src="haber.kapak" alt="">
								</figure>
								<div class="city_blog_text">
										<span>{{haber.haberTarihi}}</span>
									<h4>{{haber.haberAdi}}</h4>
									<p>{{haber.haberAciklamasi}}</p>
                  <div class="city_blog_social">
										<a class="theam_btn border-color color" @click="detayagit(haber.haberId)" tabindex="0">Habere Git</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	<!--CITY BLOG WRAP END-->

			<!--CITY JOBS WRAP START-->
			<div class="city_jobs_wrap"
      :style="{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        backgroundImage:`url(${this.Baskanbilgisi.kapak})`
        }"
      >
				<div class="city_jobs_fig">
					<div class="city_job_text">
						<span>{{Belediyebilgiler.belediyeadi}} Belediyesi</span>
						<h2>Başkanımız</h2>
						<p> {{Belediyebilgiler.belediyeadresi}}</p>
              <router-link  class="theam_btn" tabindex="0" to="/eskibaskanlar">
                Baskanlarımız
              </router-link>
					</div>
				</div>
				<div class="city_jobs_list">
					<ul>
						<li>
							<div class="city_jobs_item overlay">
								<span><i class="fa icon-team"></i></span>
								<div class="ciy_jobs_caption">
									<p>{{BaskanBilgisi.mesaj.slice(0,150)}}...</p>
									<a>
                    <router-link to="/mesaj">
                      Mesaja Git
                    </router-link>
                  </a>
								</div>
							</div>
						</li>
						<li>
							<div class="city_jobs_item pull-right overlay">
								<div class="ciy_jobs_caption">
									<p>{{BaskanBilgisi.baskanslogan}}</p>
									<a>
                    <router-link to="/">
                      Slogan'a Git
                    </router-link>
                  </a>
								</div>
								<span><i class="fa icon-urban"></i></span>
							</div>
						</li>
						<li>
							<div class="city_jobs_item overlay">
								<span><i class="fa icon-portfolio"></i></span>
								<div class="ciy_jobs_caption">
									<p>{{BaskanBilgisi.ozgecmis.slice(0,150)}}...</p>
									<a>
                    <router-link to="/ozgecmis">
                      Baskan Ozgecmis'e Git
                    </router-link>
                  </a>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<!--CITY JOBS WRAP END-->

      <!--CITY PROJECT WRAP START-->
			<div class="city_project_wrap">
				<div class="container-fluid">
					<!--SECTION HEADING START-->
					<div class="section_heading center">
						<span>{{Belediyebilgiler.belediyeadi}} Belediyesi</span>
						<h2>Projeler</h2>
					</div>
					<!--SECTION HEADING END-->
					<div class="city_project_mansory">
						<ul id="filterable-item-filter-1 string">
							<li>
                <a class="active"
                
                >
                  Tüm Projeler
                </a>
              </li>
							<li>
                <a
                
                >
                Planlanan Projeler
                </a>
              </li>
							<li>
                <a>
                Devam Eden Projeler
                </a>
              </li>
							<li>
                <a>
                  Tamamlanmış Projeler
                </a>
              </li>
						</ul>
					</div>
					<div class="city-project-slider" id="change-area"
          v-for="(proje,index) in Projeler.slice(0,15)"
          :key="index"
          >
						<div>
							<div class="city_project_fig col-md-3">
								<figure class="overlay">
									<img :src="proje.kapak" alt="">
									<div class="city_project_text">
										<span><i class="fa icon-plastic"></i></span>
										<a @click="detayagit(proje.projeId)">{{proje.projeAdi.slice(0,30)}}</a>
										<h3><a @click="detayagit(proje.projeId)">{{proje.projeAciklamasi.slice(0,37)}}...</a></h3>
									</div>
								</figure>
							</div>
						</div>	
					</div>
				</div>
			</div>	
			<!--CITY PROJECT WRAP END-->

      <!--CITY NEWS WRAP START-->
			<div class="city_news_wrap">
				<div class="container">
					<div class="row">
						<div class="col-md-8">
							<!--SECTION HEADING START-->
							<div class="section_heading margin-bottom">
								<span>Bizden haberdar ol!</span>
								<h2>Güncel Duyurular</h2>
							</div>
							<!--SECTION HEADING START-->
							<div class="row">
								<div class="col-md-6 col-sm-6">
									<div class="city_news_fig"
                  v-for="(duyuru,index) in Duyurular.slice(0,1)"
                    :key="index"
                  >
										<figure class="box">
											<div class="box-layer layer-1"></div>
											<div class="box-layer layer-2"></div>
											<div class="box-layer layer-3"></div>
											<img :src="duyuru.kapak" alt="">
										</figure>
										<div class="city_news_text">
											<h2></h2>
											<ul class="city_news_meta">
												<li><a href="#">{{duyuru.duyruilanTarihi}}</a></li>
											</ul>
											<p>
                        {{duyuru.duyruilanAciklamasi}}</p>
											<a class="theam_btn border-color color"  tabindex="0">
                        <router-link to="/haberler">
                          Tüm Duyurular
                        </router-link>
                      </a>
										</div>
									</div>
								</div>
								<div class="col-md-6 col-sm-6">
									<div class="city_news_row">
										<ul
                     v-for="(duyuru,index) in Duyurular.slice(1,5)"
                    :key="index"
                    >
											<li>
												<div class="city_news_list">
													<figure class="box">
														<div class="box-layer layer-1"></div>
														<div class="box-layer layer-2"></div>
														<div class="box-layer layer-3"></div>
														<img :src="duyuru.kapak" alt="">
													</figure>
													<div class="city_news_list_text">
														<h5>{{duyuru.duyruilanAdi.slice(0,10)}} </h5>
														<ul class="city_news_meta">
															<li><a href="#">{{duyuru.duyruilanTarihi}}</a></li>
                              <a class="theam_btn border-color color" @click="detayagit(duyuru.duyruilanId)" tabindex="0">Duyuruya git</a>
														</ul>
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>	
						</div>
						<div class="col-md-4">
							<div class="city_news_form">
								<div class="city_news_feild feild2">
									<h4>Meclis Kararları</h4>
									<div class="city_document_list"
                  v-for="(karar, index) in Kararlar"
                  :key="index"
                  >
										<ul>
											<li><a href="#"><i class="fa icon-document"></i>
                      {{ karar.kararAdi }}
                      {{karar.kararTarihi.split(" ")[0]}}
                      </a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>	
			<!--CITY NEWS WRAP END-->

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  data() {
    return {
      urll: "",
      Haberler: [],
      Slides: [],
      Ilanlar: [],
      Kararlar: [],
      Yerler: [],
      Vefatedenler: [],
      BaskanBilgisi:{},
      Baskanbilgisi: {},
      Ilce: {},
      Projeler: [],
      Duyurular: [],
      Cralanlar: [],
      Birimler: [],
      loadkont: false,
      kont: false,
      domAD: "",
      Belediyebilgiler: {},
    };
  },
  components: {},
  // linear-gradient(0deg, rgba(255, 255, 255, 0.9),rgba(255, 255, 255, 0.9)),
  //background-repeat: round; background-attachment: fixed;
  async created() {
    const domainAd = window.location.hostname;
    this.domAD = domainAd;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.belediyecek();
    await this.haberlercek();
    await this.duyurularcek();
    await this.$store.dispatch("vericekme", "Genel");
    await this.$store.dispatch("vericekme", "Slides");
    this.Slides = this.$store.getters.getSlides;
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    this.BaskanBilgisi = this.$store.getters.getBaskan;
    this.projeler = this.$store.getters.getProjeler;
    console.log('burdan sonra gelecek');
    console.log(this.Projeler);
    this.Ilce = this.$store.getters.getIlce;
    this.urll = `url(${this.Belediyebilgiler.kapak})`;
    this.loadkont = true;
    await this.baskancek();
    await this.duyuruilancek();
    await this.kararlarcek();
    await this.yerlercek();
    await this.projelercek();
    await this.createdcek();
    this.scriptekleslide();
    await this.Birimlercek();
    await this.tarihcecek();
    await this.Cralanlarcek();
     console.log(this.Haberler);
     console.log("buradan sonra id gleecek")
     console.log(this.YouTubeGetID("https://www.youtube.com/watch?v=hWv2puPpN8g"));
  },
  methods: {
    
//   YouTubeGetID(videoId) {
//   var video_id = videoId;  
//   video_id = video_id.split('v=')[1];
//   var ampersandPosition = video_id.indexOf('&');
//   if(ampersandPosition != -1) {
//   video_id = video_id.substring(0, ampersandPosition);
// }
//   return video_id
// },

    detayagit(ID) {
      this.$router.push("/haberdetay/" + ID);
    },
    birimdetaygit(birimIDD) {
      this.$router.push("/birimdetay/" + birimIDD);
    },
    projedetayagit(ID) {
      this.$router.push("/projedetay/" + ID);
    },
    yerdetayagit(ID) {
      this.$router.push("/kentrehberidetay/" + ID);
    },
    cralanlardetayagit(ID) {
      this.$roter.push("/cralandetay/" + ID);
    },
    duyurudetayagit(ID) {
      this.$router.push("/duyurudetay/" + ID);
    },
    vefatlardetaygit(ID) {
      this.$router.push("/vefatdetay/" + ID);
    },
    async haberlercek() {
      await this.$store.dispatch("vericekme", "Haberler");
      const habermap = this.$store.getters.getHaberler;
      this.Haberler = Object.values(habermap);
 /*    console.log("burası neresi")
      this.Haberler.forEach(el => {
        var videoId = this.YouTubeGetID(el.youtubeLink)
        console.log(videoId)
      }); */
      this.Haberler.sort(function (b, a) {
        return a.haberId - b.haberId;
      });
      this.kont = true;
    },
    async belediyecek() {},
    async scriptekleslide() {
      // SCRİPT
      // let customscript = await document.createElement("script");
      // customscript.setAttribute("src", "js/custom-script.js");
      // document.head.appendChild(customscript);
      // // SCRİPT
      // let customscript2 = await document.createElement("script");
      // customscript2.setAttribute("src", "js/main.js");
      // document.head.appendChild(customscript2);
      // SCRİPT
      // let customscript3 = await document.createElement("script");
      // customscript3.setAttribute("src", "js/plugins.js");
      // document.head.appendChild(customscript3);
      // // SCRİPT
      // let customscript4 = await document.createElement("script");
      // customscript4.setAttribute("src", "js/jquery-2.1.1.min.js");
      // document.head.appendChild(customscript4);
      // SCRİPT
      let customscript5 = await document.createElement("script");
      customscript5.setAttribute("src", "js/script.js");
      document.head.appendChild(customscript5);
      //   let customscript7 = await document.createElement("script");
      // customscript7.setAttribute("src", "js/custom.js");
      // document.head.appendChild(customscript7);
      let customscript6 = await document.createElement("script");
      customscript6.setAttribute("src", "assets/owl-carousel/owl.carousel.min.js");
      document.head.appendChild(customscript6);
    },
    async baskancek() {
      await this.$store.dispatch("vericekme", "Baskan");
      this.Baskanbilgisi = this.$store.getters.getBaskan;
    },
    async duyurularcek() {
      const domainAd = window.location.hostname;
      await this.$store.dispatch("domainbelirle", domainAd);
      await this.$store.dispatch("vericekme", "DuyuruIlanlar");
      const duyurulist = this.$store.getters.getDuyuruIlanlar;
      this.Duyurular = Object.values(duyurulist);
      this.Duyurular.sort(function (b, a) {
      return a.duyruilanId - b.duyruilanId;
    });
      this.loadkont=true;
  },
    async tarihcecek() {
      const domainAd = window.location.hostname;
      await this.$store.dispatch("domainbelirle", domainAd);
      await this.$store.dispatch("vericekme", "Ilce");
      await this.$store.dispatch("vericekme", "Genel");
      this.Ilce = this.$store.getters.getIlce;
      this.loadkont = true;
  },
    async kararlarcek() {
      await this.$store.dispatch("vericekme", "Kararlar");
      const kararlar1 = this.$store.getters.getKararlar;
      this.Kararlar = Object.values(kararlar1);
      this.Kararlar.sort(function (b, a) {
        return a.kararId - b.kararId;
      });
    },
    async yerlercek() {
      await this.$store.dispatch("vericekme", "Yerler");
      const yerler1 = this.$store.getters.getYerler;
      this.Yerler = Object.values(yerler1);
      this.Yerler.sort(function (b, a) {
        return a.yerId - b.yerId;
      });
    },
    async duyuruilancek() {
      await this.$store.dispatch("vericekme", "DuyuruIlanlar");
      const dilanlar = this.$store.getters.getDuyuruIlanlar;
      this.Ilanlar = Object.values(dilanlar);
      this.Ilanlar.sort(function (b, a) {
        return a.duyruilanId - b.duyruilanId;
      });
    },
    async projelercek() {
      await this.$store.dispatch("vericekme", "Projeler");
      const projeler1 = this.$store.getters.getProjeler;
      this.Projeler = Object.values(projeler1);
      this.Projeler.sort(function (b, a) {
        return a.projeId - b.projeId;
      });
    },
    async createdcek() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Vefatlar");
    const vefatlist = this.$store.getters.getVefatlar;
    this.Vefatedenler = Object.values(vefatlist);
    this.Vefatedenler.sort(function (b, a) {
      return a.vefatId - b.vefatId;
    });
    this.loadkont = true;
    },
    async Cralanlarcek() {
      await this.$store.dispatch("vericekme", "Cralanlar");
      const Cralanlar1 = this.$store.getters.getCralanlar;
      this.Cralanlar = Object.values(Cralanlar1);
      this.Cralanlar.sort(function (b,a) {
        return a.cralanlarId - b.cralanlarId;
      });
    },
      async Birimlercek() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Birimler");
    const birimmap = this.$store.getters.getBirimler;
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    this.Birimler = Object.values(birimmap);
    this.Birimler.sort(function (b, a) {
      return a.birimId - b.birimId;
    });
    this.loadkont = true;
  },
  },
  computed: {
  // Sort Filter merhod ------------------ 
  //    sortedProjeler: function() {
  //   function compare(a, b) {
  //     if (a.projeId < b.projeId)
  //       return -1;
  //     if (a.projeId > b.projeId)
  //       return 1;
  //     return 0;
  //   }
  //   return this.Projeler.sort(compare);
  // },
  //----------------------------------------

 // Sort Filter merhod ------------------ 
//     var vm = new Vue({
// 	el:  "#project",
// 	data: {
// 		projectarea: [
// 			tihs.Projeler
// 		],
// 		selectedProjeler: "All"
// 	},
// 	computed: {
// 		filteredProjeler: function() {
// 			var vm = this;
// 			var projeler = vm.selectedProjeler;
			
// 			if(projeler === "All") {
// 				return vm.projeler;
// 			} else {
// 				return vm.projeler.filter(function(person) {
// 					return person.projeler === projeler;
// 				});
// 			}
// 		}
// 	}
// })
// }
//----------------------------------------
    teststyle() {
      return {
        background:
          "linear-gradient(0deg, rgba(255, 255, 255, 0.9),rgba(255, 255, 255, 0.9))," +
          this.urll,
        "background-repeat": "round",
        "background-attachment": "fixed",
      };
    },
  },
  mounted() {
    if (window.location.hostname == "kaymakli.zeplinx.com") {
      let twitscrpt = document.createElement("script");
      twitscrpt.setAttribute("src", "https://platform.twitter.com/widgets.js");
      document.head.appendChild(twitscrpt);
    }
  },
};
</script>
<style>
#videoContainment {
  position: fixed;
  width: 100% !important;
  height: 100% !important;
  left: 0;
  top: 0;
  overflow: hidden;
}
</style>
