<template>
  <div>
  <footer>
	<div class="widget_wrap overlay">
					<div class="container">
						<div class="row">
							<div class="col-md-6 col-sm-6">
								<div class="widget_list">
									<h4 class="widget_title">İLETİŞİM MERKEZLERİ</h4>
									<div class="widget_text">
										<ul>
											<li><a>Belediye Santral: {{ Belediyebilgiler.iletisim }}</a></li>
											<li><a>Çağrı Merkezi: {{ Belediyebilgiler.iletisim }}</a></li>
											<li><a>Zabıta İletişim Hattı: {{ Belediyebilgiler.iletisim }}</a></li>
										</ul>
										<p>{{Belediyebilgiler.belediyeadresi}}</p>
									</div> 
								</div>
							</div>
							<div class="col-md-6">
								<div class="row">
									<div class="col-md-6 col-sm-6">
										<div class="widget_list">
											<h4 class="widget_title">Hızlı Menü</h4>
											<div class="widget_service">
												<ul>
													<li>
														<router-link to="/tarihce">Tarihçe</router-link>
													</li>
													<li>
														<router-link to="/kentrehberi">Kent Rehberi</router-link>
													</li>
													<li>
														<router-link to="/vefat">Vefat Edenler</router-link>
													</li>
													<li>
														<router-link to="/galeri">Galeri</router-link>
													</li>
													<li>
														<router-link to="/muhtarlar">Muhtarlarımız</router-link>
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div class="col-md-6 col-sm-6">
										<div class="widget_list">
											<h4 class="widget_title">Başkan Hızlı Menü </h4>
											<div class="widget_service">
												<ul>
													<li>
														<router-link to="/meclisuyeleri">Meclis Üyeleri</router-link>
													</li>
													<li>
														<router-link to="/birimler">Birimler</router-link>
													</li>
													<li>
														<router-link to="/baskangaleri">Başkan Albüm</router-link>
													</li>
													<li>
														<router-link to="/mesaj">Başkan'dan Mesaj</router-link>
													</li>
													<li>
														<router-link to="/ozgecmis">Başkan Özgeçmiş</router-link>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="widget_copyright">
								<div class="col-md-3 col-sm-6">
									<div class="widget_logo">
										<a href="#"><img src="images/widget-logo.png" alt=""></a>
									</div>
								</div>
								<div class="col-md-6">
									<div class="copyright_text">
										<p><span>Copyright © 2021 {{Belediyebilgiler.belediyeadi}} Belediyesi </span> Tüm Hakları Saklıdır</p>
									</div>
								</div>
								<div class="col-md-3 col-sm-6">
									<div class="city_top_social">
										<ul>
											<li><a target="_blank" :href="Belediyebilgiler.twit"><i class="fa fa-facebook"></i></a></li>
											<li><a target="_blank" :href="Belediyebilgiler.face"><i class="fa fa-twitter"></i></a></li>
											<li><a target="_blank" :href="Belediyebilgiler.twit"><i class="fa fa-linkedin"></i></a></li>
											<li><a target="_blank" :href="Belediyebilgiler.twit"><i class="fa fa-youtube"></i></a></li>
											<li><a target="_blank" :href="Belediyebilgiler.twit"><i class="fa fa-google"></i></a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>		
	</div>
	</footer>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      Belediyebilgiler: {},
      Baskanbilgisi: {},
    };
  },
  methods: {
    async baskancek() {
      await this.$store.dispatch("vericekme", "Baskan");
      this.Baskanbilgisi = this.$store.getters.getBaskan;
    },
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    await this.baskancek();
  },
};
</script>
<style>
#footerMenu ul li a {
  color: #fff;
  text-decoration: none;
}
#footerMenu ul li a:hover {
  color: #fff !important;
  border-bottom: 1px solid #fff;

  border-left: 15px solid #fff;

  padding-left: 10px;
  transition: all 0.4s ease;
}
</style>